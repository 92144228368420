import React from "react";
import Main from "../../component/StudentDashBoard/StudentDashBoard";

function StudentDashBoard() {
  return (
    <div>
      <Main />
    </div>
  );
}

export default StudentDashBoard;
